<template>
  <LoadingLayout></LoadingLayout>
</template>

<script>
/* eslint-disable max-len */
import LoadingLayout from "@/layouts/LoadingLayout.vue";

export default {
  name: "Oidc",
  components: {
    LoadingLayout
  },
  created() {
    this.$store.commit("imcSession/UPDATE_CURRENT", this.$route.query);
    if (this.$store.getters["user/isAuthenticated"]) {
      this.$router.push("/oidc-imc/oauth").catch(() => {});
    } else {
      const m =
        process.env.VUE_APP_PIN_ENV === "test"
          ? "https://nrl-qa.nrl.digital/"
          : "https://www.nrl.com/";
      const clientId = process.env.VUE_APP_PIN_ENV === "test" ? "grassrootstest" : "grassroots";
      const redirectUri = this.$store.getters["root/redirectUriOidc"];
      const url = `/account/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid email profile offline_access verified&response_type=code`;
      const a = new URL(url, m);
      window.location = a;
    }
  }
};
</script>
